<template>
  <en-drawer :model-value="modelValue" title="新建其他应收" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="付款对象">
        <select-maintain
          v-model="form.data.payee"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { name: value, rsexp: 'id,name' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain
      ></en-form-item>
      <en-form-item label="应付金额"> <en-input v-model="form.data.amount"></en-input></en-form-item>
      <en-form-item label="备注"> <en-input v-model="form.data.comment"></en-input></en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudSettlementDefinitions['PayableDto']>
  },
  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: { payee: {}, amount: 0, type: { code: 'OTH', message: '其他应付' }, comment: '' },
        rules: { payee: [{ required: true, message: '请选择付款对象' }] },
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/payable/:payableId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/settlement/payable',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {}
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.form.submit()
            this.emit('update:model-value', false)
          }
        }
      }
    }
  }
})
</script>
